import React from 'react';
import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import styles from './FadeIn.module.scss';

const FadeIn = ({
    children = '',
    visible = undefined,
    timeout = 300,
    ...props
}) => {
    return (
        <CSSTransition
            classNames={styles['FadeIn']}
            in={visible}
            timeout={timeout}
            unmountOnExit
            {...props}>
            {children}
        </CSSTransition>
    );
};

FadeIn.propTypes = {
    children: PropTypes.node,
    visible: PropTypes.bool,
    timeout: PropTypes.number,
};

export default FadeIn;
