import React, { useState, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import NavigationFavorite from '../NavigationFavorite';
import NavigationSearch from '../NavigationSearch';
import FadeIn from '../../transitions/FadeIn';
import { CookieStateContext } from '../../containers/BasePage/state';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

import styles from './Navigation.module.scss';

const Navigation = ({
    menu = [],
    searchPageUrl,
    favoritesPageUrl,
    languages = [],
    isSearchActive,
    pageType,
}) => {
    const { t } = useTranslation('common');
    const [activeMenuItem, setActiveMenuItem] = useState(undefined);
    const [langSwitchIsOpen, setLangSwitchIsOpen] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    const { state } = useContext(CookieStateContext) || {};
    const cookieConsent = state?.categories?.includes('functionality');

    const logotypeClasses = classNames(styles['Navigation__Logotype'], {
        [styles['Navigation__Logotype--Fade']]: activeMenuItem !== undefined,
    });

    const bylineClasses = classNames(styles['Navigation__Byline'], {
        [styles['Navigation__Byline--Fade']]: activeMenuItem !== undefined,
    });

    const searchClasses = classNames(styles['Navigation__Search'], {
        [styles['Navigation__Search--Active']]: isSearchActive,
    });

    const serviceMenuClasses = classNames(styles['Navigation__ServiceMenu'], {
        [styles['Navigation__ServiceMenu--Fade']]:
            activeMenuItem !== undefined && activeMenuItem !== 'languages',
        [styles['Navigation__ServiceMenu--Active']]:
            activeMenuItem !== undefined && activeMenuItem === 'languages',
    });

    const navClasses = classNames(styles['Navigation'], {
        [styles['Navigation--HomePage']]: pageType == 'HomePage',
    });

    const navigationContainerClasses = classNames(
        styles['Navigation__Container'],
        'Navigation__Container',
        {
            [styles['Navigation__Container--IsSearching']]: showSearch,
        }
    );

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 75) {
                setActiveMenuItem(undefined);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleBlockScroll = (e) => {
            if (e.keyCode === 40 || e.keyCode === 38) {
                e.preventDefault();
            }
            // const UP = 38;
            // const DOWN = 40;
        };
        const handleKeyPress = (e) => {
            const ESC = 27;
            const UP = 38;
            const DOWN = 40;
            const currentElement = document.activeElement;
            const isButton = currentElement
                ? currentElement.classList.value.indexOf(
                      styles['Navigation__ActiveLanguage']
                  ) > -1
                : '';
            const isLink = currentElement
                ? currentElement.classList.value.indexOf(
                      styles['Navigation__LanguageLink']
                  ) > -1
                : '';
            const keyCode = e.keyCode;

            if (keyCode === ESC) {
                setActiveMenuItem(undefined);
                setLangSwitchIsOpen(false);
            }

            // We are at the first suggestion and press up
            if (isLink && keyCode === UP && !currentElement.previousSibling) {
                currentElement.parentElement.previousSibling.focus();
            }

            // We are not at the first suggestion and press up
            if (isLink && keyCode === UP && currentElement.previousSibling) {
                currentElement.previousSibling.focus();
            }

            // We are on the language dropdown button
            if (isButton && keyCode === DOWN) {
                currentElement.nextSibling.querySelector('a').focus();
            }

            // We press down inside dropdown
            if (isLink && keyCode === DOWN && currentElement.nextSibling) {
                currentElement.nextSibling.focus();
            }

            // We are on last item
            if (isLink && keyCode === DOWN && !currentElement.nextSibling) {
                currentElement.focus();
            }
        };

        document.addEventListener('keyup', handleKeyPress);
        if (langSwitchIsOpen) {
            document.removeEventListener('keydown', handleBlockScroll);
        }

        return () => {
            document.removeEventListener('keyup', handleKeyPress);
            if (langSwitchIsOpen) {
                document.removeEventListener('keydown', handleBlockScroll);
            }
        };
    }, [langSwitchIsOpen]);

    const activateSearch = () => {
        if (
            typeof window !== 'undefined' &&
            window.location.pathname !== '/' &&
            searchPageUrl.indexOf(window.location.pathname) > -1
        ) {
            document
                .querySelector('.SearchFilter__HeadingLabel')
                .scrollIntoView({
                    behavior: 'smooth',
                });
        } else {
            setShowSearch(true);
        }
    };

    const handleClickItem = (item) => {
        setLangSwitchIsOpen(false);

        if (item === activeMenuItem) {
            setActiveMenuItem(undefined);
            return;
        }

        setActiveMenuItem(item);
    };

    const handleLangSwitch = () => {
        setActiveMenuItem(undefined);
        setLangSwitchIsOpen(!langSwitchIsOpen);
    };

    return (
        <nav role="navigation" className={navClasses}>
            <div className={navigationContainerClasses}>
                <FadeIn visible={showSearch}>
                    <NavigationSearch
                        searchPageUrl={searchPageUrl}
                        onClose={useCallback(() => setShowSearch(false), [])}
                    />
                </FadeIn>
                {!showSearch && (
                    <>
                        <div className={logotypeClasses}>
                            {typeof window !== 'undefined' &&
                                window.location.pathname !== '/' && (
                                    <a href="/">
                                        <span className="sr-only">
                                            {t('navigation.logo')}
                                        </span>
                                    </a>
                                )}
                        </div>

                        <div className={styles['Navigation__MenuWrap']}>
                            <div className={bylineClasses}>
                                {t('navigation.byline')}
                            </div>
                            <div className={styles['Navigation__Menu']}>
                                {menu.map((item, idx) => (
                                    <NavItem
                                        key={idx}
                                        navId={`nav-id-${idx}`}
                                        idx={idx}
                                        onClickItem={() => {
                                            handleClickItem(item);
                                        }}
                                        isActive={item === activeMenuItem}
                                        activeMenuItem={activeMenuItem}
                                        {...item}
                                    />
                                ))}

                                {searchPageUrl && (
                                    <button
                                        className={searchClasses}
                                        onKeyUp={(e) => {
                                            const DOWN = 40;
                                            const ENTER = 13;
                                            const SPACE = 32;
                                            if (
                                                [DOWN, ENTER, SPACE].indexOf(
                                                    e.keyCode
                                                ) > -1
                                            ) {
                                                activateSearch();
                                            }
                                        }}
                                        onClick={() => activateSearch()}>
                                        <span className="sr-only">
                                            {t('navigation.search')}
                                        </span>
                                    </button>
                                )}

                                {favoritesPageUrl && cookieConsent && (
                                    <NavigationFavorite
                                        favoritesPageUrl={favoritesPageUrl}
                                    />
                                )}

                                <div className={serviceMenuClasses}>
                                    <LanguageSwitch
                                        languages={languages}
                                        handleLangSwitch={handleLangSwitch}
                                        langSwitchIsOpen={langSwitchIsOpen}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </nav>
    );
};

const LanguageSwitch = ({ languages, handleLangSwitch, langSwitchIsOpen }) => {
    const { t } = useTranslation('common');
    const activeLanguage = languages.filter((l) => l.isActive)[0];

    const switchMenuClass = classNames(styles['Navigation__LanguageMenu'], {
        [styles['Navigation__LanguageMenu--Open']]: langSwitchIsOpen,
    });

    const languageSwitcherClasses = classNames(
        styles['Navigation__LanguageSwitcher'],
        {
            [styles['Navigation__LanguageSwitcher--Open']]: langSwitchIsOpen,
        }
    );

    if (!activeLanguage) {
        return null;
    }

    return (
        <div className={languageSwitcherClasses}>
            <button
                onClick={handleLangSwitch}
                aria-expanded={langSwitchIsOpen}
                aria-controls="language-switch"
                className={styles['Navigation__ActiveLanguage']}>
                {t(`navigation.languages.${activeLanguage.langCode}`)}
            </button>
            <div
                id="language-switch"
                aria-hidden={!langSwitchIsOpen}
                className={switchMenuClass}>
                {languages.map((item, idx) => {
                    const checkBoxClass = classNames(
                        styles['Navigation__Checkbox'],
                        {
                            [styles['Navigation__Checkbox--Active']]:
                                item.isActive,
                        }
                    );

                    return (
                        <a
                            className={styles['Navigation__LanguageLink']}
                            key={idx}
                            lang={item.langCode}
                            href={item.href}>
                            <span
                                className={checkBoxClass}
                                aria-hidden={true}
                            />
                            {t(`navigation.languages.${item.langCode}`)}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

const NavItem = ({
    title,
    href,
    pageType,
    items,
    onClickItem,
    isActive,
    activeMenuItem,
    navId,
}) => {
    const linkClass = classNames(styles['Navigation__NavLink'], {
        [styles[`Navigation__NavLink--${pageType}`]]: pageType,
        [styles['Navigation__NavLink--ActivePage']]: isActive,
    });

    if (pageType === 'Explore') {
        onClickItem = undefined;
    }

    return (
        <>
            {items && items.length > 0 ? (
                <>
                    <button
                        className={styles['Navigation__NavItem']}
                        aria-expanded={isActive}
                        onClick={onClickItem}>
                        <span className={linkClass}>{title}</span>
                    </button>
                    <Dropdown
                        navId={navId}
                        visible={isActive}
                        items={items}
                        title={title}
                        href={href}
                        activeMenuItem={activeMenuItem}
                    />
                </>
            ) : (
                <a className={linkClass} href={href}>
                    {title}
                </a>
            )}
        </>
    );
};

const Dropdown = ({ items, visible, navId, title, href, activeMenuItem }) => {
    const dropdownClasses = classNames(styles['Navigation__Dropdown'], {
        [styles['Navigation__Dropdown--Visible']]: visible,
        [styles['Navigation__Dropdown--FadeOut']]:
            activeMenuItem !== undefined && activeMenuItem === false,
    });

    let newItems = items.filter((item) => item.items.length > 0);

    return (
        <div id={navId} className={dropdownClasses}>
            <div className={styles['Navigation__DropdownWrap']}>
                <div className={styles['Navigation__DropdownContainer']}>
                    <div className={styles['Navigation__DropdownHeader']}>
                        <a
                            href={href}
                            className={
                                styles['Navigation__DropdownHeaderLink']
                            }>
                            <span>{title}</span>
                        </a>
                    </div>
                    {newItems.map((lists, index) => (
                        <div
                            className={styles['Navigation__DropdownOuterList']}
                            key={index}>
                            {lists.items &&
                                lists.items.map((list, listIndex) => (
                                    <DropdownList {...list} key={listIndex} />
                                ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const DropdownList = ({ label, links, showArrowIcon }) => (
    <div className={styles['Navigation__DropdownInnerWrap']}>
        <h2 className={styles['Navigation__DropdownListTitle']}>{label}</h2>

        <ul>
            {links &&
                links.map((item, index) => {
                    const linkClasses = classNames(
                        styles['Navigation__DropdownLink'],
                        {
                            [styles['Navigation__DropdownLink--Arrow']]:
                                showArrowIcon,
                            [styles['Navigation__DropdownLink--City']]:
                                !showArrowIcon && item.type === 'city',
                            [styles['Navigation__DropdownLink--Region']]:
                                !showArrowIcon && item.type === 'region',
                        }
                    );

                    return (
                        <li key={index}>
                            <a className={linkClasses} href={item.href}>
                                {item.title}
                            </a>
                        </li>
                    );
                })}
        </ul>
    </div>
);

DropdownList.propTypes = {
    label: PropTypes.string,
    links: PropTypes.array,
    showArrowIcon: PropTypes.bool,
};

NavItem.propTypes = {
    idx: PropTypes.number,
    title: PropTypes.string,
    href: PropTypes.string,
    pageType: PropTypes.string,
    items: PropTypes.array,
    active: PropTypes.bool,
    isActive: PropTypes.bool,
    onClickItem: PropTypes.func,
    navId: PropTypes.string,
};

Dropdown.propTypes = {
    items: PropTypes.array,
    visible: PropTypes.bool,
    navId: PropTypes.string,
};

LanguageSwitch.propTypes = {
    languages: PropTypes.array,
    setLangSwitchIsOpen: PropTypes.func,
    langSwitchIsOpen: PropTypes.bool,
};

Navigation.propTypes = {
    menu: PropTypes.array,
    searchPageUrl: PropTypes.string,
    favoritesPageUrl: PropTypes.string,
    languages: PropTypes.array,
    pageType: PropTypes.string,
    isSearchActive: PropTypes.bool,
};

export default Navigation;
